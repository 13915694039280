import _module from "module.js";
import _ from "lodash";
import is from "is";
import moment from "moment";
import jrnParser from "classes/jrnParser";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
/**
 * @ngdoc function
 * @name platformApp.controller:NavbarcontrollerCtrl
 * @description
 * # NavbarcontrollerCtrl
 * Controller of the platformApp
 */

const parseUserData = (data) => {
  let users = [];
  if (data.users) {
    _.forEach(data.users, (user) => {
      user.name = user.firstName + " " + user.lastName;
      user.email = user.emailAddress;
      users.push(user);
    });
  }
  return users;
};

const uuidRegEx = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const CALL_REPORTS_PERMISSION = "call-reports";
const CHAT_QUEUES_PERMISSION = 'cc-config.chat-queues.view';
const SUPERVISOR_PAGE_VIEW_PERMISSION = "cc-supervisor";

export default class NavbarCtrl {
  constructor(
    globalState,
    gettextCatalog,
    $location,
    $mdMedia,
    language,
    portalApi,
    portalConfig,
    userService,
    $http,
    $window,
    $document,
    pbxUtil,
    authService,
    $localStorage,
    $state,
    partnerInfo,
    $q,
    featuresService,
    licensesService,
    FEATURE_FLAGS,
    Jbcs,
    $mdDialog,
    $rootScope,
    langSwitcher,
    tryNewGoToAdminForm,
    $log
  ) {
    "ngInject";
    this.tryNewGoToAdminForm = tryNewGoToAdminForm;
    this.jrn = jrnParser;
    this.globalState = globalState;
    this.gettextCatalog = gettextCatalog;
    this.$location = $location;
    this.$mdMedia = $mdMedia;
    this.$mdDialog = $mdDialog;
    this.language = language;
    this.portalApi = portalApi;
    this.selectedPbx = globalState.selectedPbx
    this.userService = userService;
    this.$window = $window;
    this.$document = $document;
    this.pbxUtil = pbxUtil;
    this.authService = authService;
    this.$localStorage = $localStorage;
    this.$state = $state;
    this.partnerInfo = partnerInfo;
    this.$q = $q;
    this.featuresService = featuresService;
    this.licensesService = licensesService;
    this.FEATURE_FLAGS = FEATURE_FLAGS;
    this.$rootScope = $rootScope;
    this.Jbcs = Jbcs;
    this.http = $http;
    this.goToAdminUrl = ''
    this.superAdminOrMore = false

    this.bannerMessages = [];

    this.whatsNewIcon = portalConfig.whatsNewOff;

    this.displayReleaseNotes = false;

    this.isBrowserSupported = globalState.isBrowserSupported;
    this.$document[0].title = this.gettextCatalog.getString(
      "PBX Administration"
    );
    this.gotoIcon = portalConfig.gotoIcon;
    this.hvoiceIcon = portalConfig.hvoiceIcon
    this.app = {
      name: gettextCatalog.getString("Administration"),
      icon: "",
    };
    this.user = {};
    this.$q
      .all([userService.getUserInfo(), this.language.getSelectedLanguage()])
      .then((response) => {
        const userInfo = response[0];
        this.globalState.currentLanguage = this.currentLang = response[1];

        // taking first user 'cause lazy
        this.user = parseUserData(userInfo).shift() || {
          name: userInfo.username,
          email: userInfo.emailAddress,
        };

        this.user.status = {
          indicator: "available",
          text: "Available",
          message: "",
        };

        // 05/19/2018: disable 'Improved navigation'
        // this.checkImprovedNavigation(userInfo)

        let jiveId = userInfo.username;
        this.globalState.isJiveEmployee =
          !_.includes(jiveId, "@") || _.includes(jiveId, "auburn@example.com");

        this.userData = this.globalState.userData = {
          jiveId: userInfo.username,
          users: userInfo.users,
          partnerUsers: userInfo.partnerUsers,
          authorities: userInfo.authorities,
        };
        this.isHvoice = this.globalState.isHvoice;
        this.showFeedback =
          !this.isHvoice && this.currentLang.langCode.startsWith("en");
        this.displayReleaseNotes =
          !this.isHvoice && this.currentLang.langCode.startsWith("en");

        // get specified pbxId
        this.determinePbxId().then((pbxId) => {
          if (pbxId) {
            this.partnerInfo.partnerApi = this.portalApi.partnerBff.partners;
            this.partnerInfo.pbxApi = this.portalApi.pbx.pbxs(pbxId);
            this.$q
              .all([
                this.portalApi.pbx.pbxs(pbxId).get({ cache: true }),
                this.partnerInfo.get(pbxId),
                this.featuresService.getAllContextFeatures(pbxId),
                this.licensesService.getLicenses(pbxId),
                this.portalApi.numbers.pbxs(pbxId).pbxSmsCampaignDetails().get()
                  .then(response => response.plain())
                  .catch(() => {
                    return {
                      pbxHasACampaign: false,
                      pbxIsUsingSms: false,
                      pbxMayNeedACampaignToUseSms: false,
                      pbxNeedsACampaignToUseSms: false,
                      userIsAddOnAdmin: false
                    }
                  })
              ])
              .then((data) => {
                let pbx = data[0]
                this.pbxUtil.redirectForWeightedLicensePbx(pbx)
                this.featureFlagApi = this.portalApi.location.accounts(pbx.lmiAccountKey).featureFlags

                pbx.partnerInfo = data[1];
                pbx.pbxSmsCampaignDetails = data[4]
                let featureFlags = data[2];
                let licenses = data[3];
                if (
                  is.include($location.host(), "hvoice") &&
                  pbx.partner == null
                ) {
                  pbx.partnerInfo.supportEmailAddress = null;
                  pbx.partnerInfo.supportPhoneNumber = null;
                  pbx.partnerInfo.supportUrl = null;
                }

                this.userService
                  .hasPlatformReadOnlyAccessPermissions()
                  .then((hasPlatformReadOnlyAccessPermission) => {
                    this.isPlatformReadOnlyAccess = this.globalState.isPlatformReadOnlyAccess = hasPlatformReadOnlyAccessPermission;
                  });

                Promise.all([
                  this.userService.hasPbxAdminPermission(pbx),
                  this.userService.hasManageSeatsPermission(pbx, true),
                  this.userService.hasManageUsersPermission(pbx, true),
                  this.userService.hasManageGroupsPermission(pbx, true),
                  this.userService.hasManageSettingsPermission(pbx, true),
                  this.userService.canManagePbxThroughPartner(pbx),
                ]).then(data => {
                  this.isPbxAdmin = this.globalState.isPbxAdmin = data[0]
                  this.globalState.isUserManageSeatsOnly = data[1]
                  this.globalState.isUserManageUsersOnly = data[2]
                  this.globalState.isUserManageGroupsOnly = data[3]
                  this.globalState.isUserManageSettingsOnly = data[4]
                  this.globalState.canManagePbxThroughPartner = data[5]
                  this.selectedPbx = this.globalState.selectedPbx = pbx;
                  this.globalState.isUnifiedAdminEnabled =
                    pbx.unifiedAdmin || pbx.billedByLicense

                  if (!this.pbxUtil.redirectForUnifiedAdminPbx(pbx)) {
                    this.pbxUtil.redirectForBossPartnerPbx(pbx)
                  }

                  this.noPbx = false;
                  this.goToAdminUrl = this.pbxUtil.getGoToAdminUrl(this.selectedPbx)
                  if (pbx.activationDate) {
                    this.globalState.selectedPbx.activationDate = new Date(
                      pbx.activationDate + " 00:00:00"
                    );
                  }
                  this.superAdminOrMore = this.globalState.isUserSuperAdmin || this.globalState.isPlatformAdmin

                  this.globalState.selectedPbx.isActivated =
                    pbx.state === "ACTIVE" &&
                    (pbx.activationDate
                      ? !moment(pbx.activationDate).isAfter(moment.utc())
                      : true);
                  this.globalState.selectedPbx.analyticsEnabled = false;
                  this.globalState.selectedPbx.analyticsAuthorized = false;
                  this.globalState.selectedPbx.alertCenterEnabled = false;
                  this.globalState.selectedPbx.callReportsEnabled = false;
                  this.globalState.selectedPbx.featureFlags = {};
                  this.isNA = this.globalState.isNA;

                  this.$rootScope.$on(
                    "userContextLoaded",
                    (_, userContext) => {
                      const permissions = userContext.permissions;
                      this.globalState.selectedPbx.callReportsEnabled = permissions.includes(
                        CALL_REPORTS_PERMISSION
                      );
                      this.globalState.selectedPbx.chatQueuesEnabled = permissions.includes(
                        CHAT_QUEUES_PERMISSION
                      );
                    }
                  );

                  // Store all feature flags on the PBX with their state as a boolean
                  this.globalState.selectedPbx.featureFlags = {};
                  featureFlags.forEach((feature) => {
                    if (
                      feature.feature === this.FEATURE_FLAGS.contactCenter
                    ) {
                      this.$rootScope.$on(
                        "userContextLoaded",
                        (_, userContext) => {
                          const isAuthorized =
                            userContext.permissions.includes(
                              CALL_REPORTS_PERMISSION
                            ) ||
                            userContext.permissions.includes(
                              SUPERVISOR_PAGE_VIEW_PERMISSION
                            );
                          this.globalState.selectedPbx.analyticsEnabled =
                            (isAuthorized ||
                              !this.globalState.isHvoice) &&
                            feature.setting === "ENABLED";
                          this.globalState.selectedPbx.analyticsAuthorized = isAuthorized;
                        }
                      );
                    }

                    if (feature.feature === this.FEATURE_FLAGS.tryNewGoToAdmin) {
                      this.globalState.selectedPbx.isTryNewGoToAdminEnabled =
                        feature.setting === "ENABLED";
                    }

                    if (
                      feature.feature === this.FEATURE_FLAGS.vfmAccessCodeSize
                    ) {
                      this.globalState.selectedPbx.vfmAccessCodeSizeEnabled =
                        feature.setting === 'ENABLED'
                    }

                    if (
                      feature.feature === this.FEATURE_FLAGS.newCallReports
                    ) {
                      this.globalState.selectedPbx.newCallReportsEnabled =
                        feature.setting === "ENABLED";
                    }

                    if (
                      feature.feature === this.FEATURE_FLAGS.gtcBillingEnabled
                    ) {
                      this.globalState.selectedPbx.gtcBillingEnabled =
                        feature.setting === "ENABLED";
                    }

                    this.globalState.selectedPbx.featureFlags[
                      feature.feature
                    ] = feature.setting === "ENABLED";
                  });
                  this.handleDefaultHVoiceLogo()
                  this.setupBanners()
                  this.setGotoFavicon()

                  this.globalState.selectedPbx.licenses = licenses;

                  this.licensesService.getEntitlements(pbx.lmiAccountKey).then((entitlements) => {
                    this.globalState.selectedPbx.entitlements = entitlements;
                  }).catch((error) => {
                    this.globalState.selectedPbx.entitlements = null;
                  });


                  if (this.isNA &&
                    !this.globalState.isUserManageSeatsOnly &&
                    !this.globalState.isUserManageUsersOnly &&
                    !this.globalState.isUserManageGroupsOnly) {
                    let jbcsApi = new this.Jbcs();
                    jbcsApi
                      .getListResult({
                        q: "",
                        offset: 0,
                        limit: 50,
                      })
                      .then((jbcData) => {
                        this.globalState.selectedPbx.alertCenterEnabled =
                          jbcData.totalCount > 0;
                      })
                      .catch((error) => {
                        $log.error(
                          "Couldn't load JBC information for alert conter"
                        );
                      });
                  }

                  if (!this.$state.params.id) {
                    this.pbxUtil.goToPbx(this.selectedPbx.domain);
                  }

                  if (
                    this.$state.params.id &&
                    uuidRegEx.test(this.$state.params.id)
                  ) {
                    this.$window.location = this.$window.location.href.replace(
                      this.$state.params.id,
                      this.selectedPbx.domain
                    );
                  }
                });
                this.userService.isUserSuperAdmin(pbx).then((isUserSuperAdmin) => {
                  this.globalState.isUserSuperAdmin = isUserSuperAdmin;
                });
              })
              .catch((error) => {
                this.$localStorage.lastPbx = "";
                this.selectedPbx = this.globalState.selectedPbx = null;
                this.noPbx = this.globalState.noPbx = true;
                this.goToAdminUrl = ''
                throw error;
                // if (error.status === 404) {
                //   this.$state.go('404');
                // } else {
                //   throw error
                // }
              });
          } else {
            this.$localStorage.lastPbx = "";
            this.selectedPbx = this.globalState.selectedPbx = null;
            this.noPbx = this.globalState.noPbx = true;
            this.goToAdminUrl = ''
          }
        });
      });

    this.$rootScope.$on('$stateChangeSuccess', async () => {
      this.setGotoFavicon();
    });

    $rootScope.$on('pbxChanged', () => {
      this.handleTryNewGoToAdminFeatureFlag();
      this.globalState.isUnifiedAdminEnabled =
        this.globalState.selectedPbx.unifiedAdmin || this.globalState.selectedPbx.billedByLicense
      this.goToAdminUrl = this.pbxUtil.getGoToAdminUrl(this.globalState.selectedPbx)
      this.setupBanners()
    });
  }

  handleTryNewGoToAdminFeatureFlag() {
    this.globalState.selectedPbx.isTryNewGoToAdminEnabled = this.globalState.selectedPbx.featureFlags[this.FEATURE_FLAGS.tryNewGoToAdmin];
  }

  setGotoFavicon() {
    const icon = this.shouldDefaultToHVoiceLogo ? this.hvoiceIcon : this.gotoIcon
    var existingElement = this.$document[0].getElementById("favicon");
    if (existingElement) {
      existingElement.type = "image/png";
      existingElement.href = icon
    } else {
      var link = this.$document[0].createElement("link");
      link.id = "favicon";
      link.type = "image/png";
      link.rel = "icon";
      link.href = icon
      this.$document[0].getElementsByTagName("head")[0].appendChild(link);
    }
  }

  handleDefaultHVoiceLogo() {
    this.shouldDefaultToHVoiceLogo = this.isHvoice
  }

  getCurrentTime() {
    return moment().utc().format('LLLL Z')
  }

  logout() {
    this.authService.logout();
  }

  goToCallReports() {
    this.pbxUtil.goToCallReports(this.globalState.selectedPbx);
  }

  goToAnalytics() {
    if (this.globalState.selectedPbx.analyticsAuthorized) {
      this.pbxUtil.goToAnalytics(this.globalState.selectedPbx);
    } else {
      this.pbxUtil.goToContactCenterMarketing();
    }
  }

  openTryGoToAdminPopUp() {
    this.tryNewGoToAdminForm.open()
  }

  goToAlertCenter() {
    this.pbxUtil.goToAlertCenter(this.globalState.selectedPbx);
  }

  goToUserHome() {
    this.pbxUtil.goToUserHome();
  }

  isBillingAllowed() {
    return (
      this.globalState.selectedPbx &&
      this.globalState.selectedPbx.billingPortalAccess
    );
  }

  goToBilling() {
    if (this.globalState.selectedPbx.gtcBillingEnabled) {
      this.pbxUtil.goToGtcBilling(this.globalState.selectedPbx);
    } else {
      this.pbxUtil.goToBilling(this.globalState.selectedPbx);
    }
  }

  goToReleaseNotes() {
    this.pbxUtil.goToReleaseNotes();
  }

  isGTSM() {
    return this.$mdMedia("gt-sm");
  }

  checkImprovedNavigation(userInfo) {
    let jiveId = userInfo.username;
    this.globalState.isJiveEmployee =
      !_.includes(jiveId, "@") || _.includes(jiveId, "auburn@example.com");

    if (
      this.globalState.isJiveEmployee &&
      this.globalState.isBrowserSupported
    ) {
      if (!this.$localStorage.newUINavigationShown) {
        this.newView = this.globalState.newView = this.$localStorage.newView = true;

        this.$mdDialog
          .show({
            template: newViewTemplate,
            parent: angular.element(this.$document.body),
            clickOutsideToClose: true,
            fullscreen: true,
            controller: function ($scope, $mdDialog) {
              "ngInject";
              $scope.cancel = function () {
                $mdDialog.cancel();
              };
            },
          })
          .then(
            () => {
              this.$localStorage.newUINavigationShown = true;
            },
            () => {
              this.$localStorage.newUINavigationShown = true;
            }
          );
      } else {
        this.newView = this.globalState.newView = this.$localStorage.newView;
      }
    } else {
      this.newView = this.globalState.newView = false;
    }
  }

  setupBanners() {
    this.bannerMessages = []
    this.setupSmsComplianceRegistrationBanner()
  }

  setupSmsComplianceRegistrationBanner() {
    let pbxSmsCampaignDetails = this.globalState.smsCampaignInformations
    if (pbxSmsCampaignDetails.needToRegisterSmsCampaign && pbxSmsCampaignDetails.pbxIsUsingSms && pbxSmsCampaignDetails.userCanRegisterSmsCampaign && !pbxSmsCampaignDetails.hasSmsCampaign) {
      this.bannerMessages.push({
        text: this.gettextCatalog.getString(`US carriers will block all unregistered SMS. Don't wait - complete SMS registration immediately to avoid disruption to your service.`),
        linkAction: () => {
          let smsCampaignUrl = this.pbxUtil.getGoToAdminUrl(this.globalState.selectedPbx) + '/sms-campaign'
          this.$window.open(smsCampaignUrl, 'isFromLegacyPortal').focus()
        },
        linkLabel: this.gettextCatalog.getString('Register now.'),
      });
    }
    if (pbxSmsCampaignDetails.needToRegisterSmsCampaign && pbxSmsCampaignDetails.pbxSmsActionRequired) {
      this.bannerMessages.push({
        text: this.gettextCatalog.getString('Action Required: Your SMS campaign was rejected and requires updates to meet compliance standards. Please update the campaign and resubmit to avoid service disruptions.'),
        linkAction: () => {
          let smsCampaignUrl = this.pbxUtil.getGoToAdminUrl(this.globalState.selectedPbx) + '/sms-campaign'
          this.$window.open(smsCampaignUrl, 'isFromLegacyPortal').focus()
        },
        linkLabel: this.gettextCatalog.getString('Update now.'),
      });
    }
    if (pbxSmsCampaignDetails.needToRegisterSmsCampaign && pbxSmsCampaignDetails.pbxHasSubIdWaitingCustomerAnswer) {
      this.bannerMessages.push({
        text: this.gettextCatalog.getString(`Action Required: Number pool approval is missing. Fill out the <a class='inline-link' href='https://support.goto.com/download/sms-campaign-number-pool-approval' target='_blank'>required form</a> and send it to <a class='inline-link' href='mailto:numbers@goto.com'>numbers@goto.com</a> to avoid service disruptions.`),
        warning: true,
      });
    }
  }

  determinePbxId() {
    // determine pbx access
    this.userService
      .hasPlatformNumberPermissionsOrHigher()
      .then((isPlatformNumbersOrHigher) => {
        this.globalState.isPlatformNumbersOrHigher = isPlatformNumbersOrHigher;
      });
    this.userService.hasPlatformAdminPermissions().then((isPlatformAdmin) => {
      this.globalState.isPlatformAdmin = isPlatformAdmin;
    });
    this.userService
      .hasPlatformFeatureFlagPermissions()
      .then((isPlatformFeatureFlag) => {
        this.globalState.isPlatformFeatureFlag = isPlatformFeatureFlag;
      });
    this.userService.hasPartnerAdminPermissions().then((isPartnerAdmin) => {
      this.globalState.isPartnerAdmin = isPartnerAdmin;
    });
    this.userService
      .hasPartnerPbxAdminPermissions()
      .then((isPartnerPbxAdmin) => {
        this.globalState.isPartnerPbxAdmin = isPartnerPbxAdmin;
      });
    return this.userService.hasPlatformPermissions().then((isPlatform) => {
      this.isPlatform = this.globalState.isPlatform = isPlatform;

      // direct user to pbx / portal
      let pbxId = this.$state.params.id || null;

      // validate pbxId is a UUID
      // http://regexr.com/3bjan
      // Don't use flag g: http://stackoverflow.com/questions/2630418/javascript-regex-returning-true-then-false-then-true-etc
      // no pbx specified in url
      if (pbxId) {
        if (uuidRegEx.test(pbxId)) {
          return pbxId;
        } else {
          // get the pbxId based on the domain
          return this.portalApi.pbx
            .pbxs("domain")
            .one(pbxId)
            .get()
            .then((pbxJrn) => {
              return this.jrn.parse(pbxJrn).getAccount();
            })
            .catch((error) => {
              this.$localStorage.lastPbx = "";
              pbxId = null;
              return null;
            });
        }
      }

      // get available pbxs
      return this.userService.getAdministeredPbxs().then((pbxs) => {
        if (this.globalState.userData.users.length > 0) {
          let firstPbx = _.find(this.globalState.userData.users, (user) => {
            return (
              // most users don't have access to Jive PBX, so let's not go there.
              user.pbxDomain !== "jive" &&
              (_.includes(user.pbxPermissions, "ADMIN") ||
                _.includes(user.pbxPermissions, "CONFIGURE_PBX_ACCESS"))
            );
          });
          if (firstPbx) {
            pbxId = this.jrn.parse(firstPbx.jrn).getAccount();
          }
        }

        if (
          !pbxId &&
          pbxs.length > 0 &&
          (isPlatform || this.globalState.userData.partnerUsers.length > 0)
        ) {
          pbxId = this.jrn.parse(pbxs[0].jrn).getAccount();
        }

        // if pbxId selected is unified-admin and there is other
        // non-unified admin PBXs, we select the first non-unified PBX
        // because else the user won't have access to the old portal anymore.
        let foundSelectedUnifiedAdminPbx = _.find(pbxs, (pbx) => {
          let iteratedPbxId = this.jrn.parse(pbx.jrn).getAccount();
          return iteratedPbxId === pbxId && pbx.unifiedAdmin;
        });

        if (foundSelectedUnifiedAdminPbx) {
          let notUnifedAdminPbx = _.find(pbxs, (pbx) => {
            return !pbx.unifiedAdmin;
          });
          if (notUnifedAdminPbx) {
            pbxId = this.jrn.parse(notUnifedAdminPbx.jrn).getAccount();
          }
        }

        return pbxId;
      });
    });
  }
}

_module.controller("NavbarCtrl", NavbarCtrl);
